import React from 'react';
import Container from 'react-bootstrap/Container';  
import { onValue, ref, set, get, update, remove, child, getDatabase } from 'firebase/database';
import './auth.css'
function Auth(props) {

    return (
<Container className='admin-main'>
admin page
</Container>
    )
}

export default Auth;