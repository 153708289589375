import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';

import logo from '../../images/BantamStringBenderLogo.png'
import grpPhoto from '../../images/BantamStringBendersGroupPhoto.png';

import './carousel.css';
function MainPageCarousel() {
  return (
    <div style={{minHeight:'360px'}}>
    <Carousel>
      <Carousel.Item>
        <Image id='LogoCar'  itemProp='image' rounded alt="Logo for The Bantam String Benders showing a silouhette of a bantam rooster on a yellow background with 'The Bantam String Benders' displayed in cureved text above and below."src={logo} className='carousel-user-item'/>
        <Carousel.Caption>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <Image id='BanPhotoCar' itemProp='image' rounded alt="Photo of the Bantam String Benders Bluegrass ensemble with their instruments performing on stage." src={grpPhoto}  className='carousel-user-item'/>

      </Carousel.Item>
    </Carousel>
    </div>
  );
}

export default MainPageCarousel;