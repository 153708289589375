import React, { useState, useEffect } from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './Components/Home/home.js';
import Auth from './Components/auth/auth.js';
import Container from 'react-bootstrap/Container';
import StartFirebase from './Components/firebaseConfig';
import Subscribe from './Components/subscribe/subscribe';

import Press from './Components/Press/press';
import SendMessage from './Components/sendMessage/sendMessage.js';
import { onValue, ref, set, get, update, remove, child, getDatabase } from 'firebase/database';


var today = new Date();
today.setHours(0,0,0,0);

function App() {
  const [shows, setShows] = useState();
  const [nextShow, setNextShow] = useState(null);
  const database = StartFirebase();
  const dbRef = ref(database);


  useEffect(() => {
    
fetch('https://rest.bandsintown.com/artists/the bantam string benders/events?app_id=e3d4586943c72d5bcd0f21c954ade0f0')
  .then(response => response.json())
  .then(data => {;
    setShows(data);
    setNextShow(data[0]);
  })
  .catch(error => console.error(error));
  }, []);

  return (
    <BrowserRouter>
      <Routes>
            <Route path="/" element={<Home dbRef={dbRef} shows={shows} nextShow={nextShow}/>} />
            <Route path="/subscribe" element={<Subscribe dbRef={dbRef}/>} />
            <Route path="/press" element={<Press/>}/>
            <Route path="/roost" element={<Auth dbRef={dbRef} />} />
            <Route path="/sendMessage" element={<SendMessage />} />
      
      </Routes>
    </BrowserRouter>
  )
}

export default App;
