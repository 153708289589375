import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';

import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import MainPageCarousel from '../Carousel/carousel';
import Subscribe from '../subscribe/subscribe';
import SendMessage from '../sendMessage/sendMessage';

import Marquee from '../Marquee/marquee.js';
import Image from 'react-bootstrap/Image';
import pressImage from '../../images/offThePress-noBackground.png';

import './home.css';

import Shows from '../Shows';
import Social from '../SocialLinks/socialLinks';


function Home(props) {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  function navPress() {
    navigate("/press");
  }

  return (
    <>
      <Stack gap={1} style={{backgroundColor: '#7f0501'}}>
        <Container className="App">
          {props.nextShow ? <Marquee nextShow={props.nextShow} /> : null}
          <MainPageCarousel />
          <Social handleShow={handleShow}/>
          <Image onClick={navPress} bsPrefix='pressimg' src={pressImage} id='Press'/>
        </Container>
      </Stack>
      {props.shows ? <Shows shows={props.shows} /> : null
      }
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Get in touch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Accordion  flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Send the Bantam String Benders a Message</Accordion.Header>
        <Accordion.Body>
          <SendMessage />
        </Accordion.Body>
      </Accordion.Item >
      <Accordion.Item eventKey="1">
        <Accordion.Header>Sign-up for the Mailing List</Accordion.Header>
        <Accordion.Body>
          <Subscribe dbRef={props.dbRef} handleClose={()=> handleClose()}/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
         
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Home;
