import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

function StartFirebase () {
    
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_apiKey,
        authDomain: process.env.REACT_APP_authDomain,
        databaseURL: process.env.REACT_APP_databaseURL,
        projectId: process.env.REACT_APP_projectId,
        storageBucket: process.env.REACT_APP_storageBucket,
        messagingSenderId: process.env.REACT_APP_messagingSenderId,
        appId: process.env.REACT_APP_appId,
        measurementId: process.env.REACT_APP_measurementId
      };

      
    const app = initializeApp(firebaseConfig);
    return getDatabase(app);
}

export default StartFirebase;