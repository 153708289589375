import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import { AiOutlineHome } from 'react-icons/ai';

import logo from '../../images/BantamStringBenderLogo.png'
import grpPhoto from '../../images/BantamStringBendersGroupPhoto.png';

import './press.css'
function Press(props) {
    
const navigate = useNavigate();

function navHome() {
  navigate("/");
}

    return (
        <Container style={{ display: 'flow-root', color: 'white',  }}>
            <br/>
            <AiOutlineHome onClick={navHome}style={{cursor: 'pointer', float: 'right', fontSize:'2em', margin: '5px'}}/>
            <Image style={{ float: 'left', width: '15vw', margin: '', marginRight: '20px', borderRadius: '5px' }} itemProp='image' alt="Logo for The Bantam String Benders showing a silouhette of a bantam rooster on a yellow background with 'The Bantam String Benders' displayed in cureved text above and below." src={logo} />
            <h1 style={{ color: 'white', marginLeft: '5px' }}>The Bantam String Benders</h1>
            <br/>
            
            <p style={{ color: 'white', fontSize:'1.3em'}}>
                The Bantam String Benders are a 4-piece American string band from the high desert plains 
                of Southeast Idaho. The band formed in 2020 from a collection of dedicated musicans who 
                met over years through personal friendships and being introduced at area open-mic events. 
                Combining bluegrass, country and folk genres, The Bantam String Benders unique brand of entertainment 
                fuses captivating group harmonies, exceptional instrument improvisation, and a driving rhythm section 
                with visually appealing single-mic choreography into a lively and dynamic performance that will make 
                you want to take up a bluegrass instrument and get in on the jam!
            </p>
            <Image style={{ float: 'right', textAlign: 'middle', width: '36vw', maxWidth:'36vw', borderRadius: '5px', margin: '10px'}} itemProp='image' alt="Photo of the Bantam String Benders Bluegrass ensemble with their instruments performing on stage." src={grpPhoto} />
            <p style={{fontSize:'1.3em'}}>
                Hailed as Southeast Idaho's premier bluegrass ensemble by a growing and dedicated base of local fans, 
                The Bantam String Benders are quickly establishing themselves as a top musical group in the region 
                and have become entertainment staples at community music festivals and events across Southeast Idaho 
                in recent years.
            </p><p style={{fontSize:'1.3em'}}>
                The Bantam String Benders are currently accepting bookings and would be tickled paisely pink to 
                entertain at your next party. For more info or to book, send an email to 
                <a href='mailTo:contact@bantamstringbenders.com'> contact@bantamstringbenders</a> or look them up 
                across social media.

            </p>
            
        </Container>
    )
}
export default Press;