import React, { useState, useRef } from 'react';

import Alert from 'react-bootstrap/Alert';
import Axios from 'axios';
import './sendMessage.css'
import Button from 'react-bootstrap/Button'

const sendEmailURL = 'https://us-central1-bantam-string-benders-site.cloudfunctions.net/sendEmail';


function SendMessage(props) {
    let db = props.dbRef;
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [subject, setSubject] = useState(null);
    const [message, setMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [showAlert, setShowAlert] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        if (id === "name") {
            setName(value);
        }
        if (id === "email") {
            setEmail(value);
        }
        if (id === "subject") {
            setSubject(value);
        }
        if (id === "message") {
            setMessage(value);
        }

    }

    const validateInput = ({ name, email, subject, message }) => {
        if (!name || !name.trim()) {
            return false;
        }

        if (!email || !email.trim()) {
            return false;
        }

        if (!subject || !subject.trim()) {
            return false;
        }

        if (!message || !message.trim()) {
            return false;
        }

        // other validations

        return true;
    };

    const resetForm = () => {
        document.getElementById("msgForm").reset();
        setName('');
        setEmail('');
        setMessage('');
        setSubject('');
    }


    const sendEmail = (e) => {
        e.preventDefault();
        Axios.get(sendEmailURL, {
            params: {
                message,
                name,
                subject,
                email
            },
        }).then(res => {
            console.log(res)
        }).catch(err => {
            setErrorMessage(err);
            setErrorMessage(true);
            flashError(err);
        });
    }

    const flashAlert = () => {
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, "4000")
    }

    const flashError = (err) => {
        setShowError(true);
    }

    const handleSubmit = (e) => {

        e.preventDefault();

        const validInput = validateInput({ name, email, subject, message });
        if (!validInput) {
            setErrorMessage('Invalid Input');
            flashError();
            return null;
        }
        
        let obj = {
            name: name,
            email: email,
            subject: subject,
            message: message
        }
        sendEmail(e);
        flashAlert();
        resetForm();

    }

    return (
        // <div>
        //     Send a message to The Bantam String Benders at <a href='mailTo:contact@bantamstringbenders.com'>contact@bantamstringbenders.com</a>
        // </div>
        <form autocomplete="off" id='msgForm' className='form'>
            <div className="form-body">
                <div className="pair name">
                    <label className="form__label" htmlFor="name">Your Name (required) </label>
                    <input className="form__input" type="text" id="name" onChange={(e) => handleInputChange(e)} required />
                </div>

                <div className="pair email">
                    <label className="form__label" htmlFor="email">Your Email (required)</label>
                    <input required='required' type="email" id="email" name="email-input" className="form__input" onChange={(e) => handleInputChange(e)} />
                </div>
                <div className="pair subject">
                    <label className="form__label" htmlFor="subject">Subject</label>
                    <input className="form__input" type="text" id="subject" onChange={(e) => handleInputChange(e)} />
                </div>
                <div className="pair message">
                    <label className="form__label" htmlFor="message">Message (required)</label>
                    <textarea rows='5' className="form__input" type="text" id="message" onChange={(e) => handleInputChange(e)} required />
                </div>
                <div>
                    {showAlert ?
                        <Alert style={{padding: '3px'}} variant='success'>
                            <p style={{marginBottom: 0, fontSize: "12px"}}>You have signed up successfully!</p>
                        </Alert>
                        : null
                    }
                    {showError ?
                        <Alert style={{padding: '3px'}} variant='danger' dismissible>
                            <p style={{marginBottom: 0, fontSize: "12px"}}>Something went wrong.</p>
                            <p style={{marginBottom: 0, fontSize: "12px"}}>{errorMessage}</p>
                        </Alert>
                        : null
                    }
                    <Button style={{ color: '#2b3100', borderColor: '#2b3100', backgroundColor: "#d0d030", marginTop: '8px' }} className='pair submit-btn' onClick={(e) => handleSubmit(e)} type="submit" >Send</Button>
                </div>
            </div>
        </form>
    )
}
export default SendMessage;