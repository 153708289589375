import React from 'react';
import ReactCurvedText from "react-curved-text";
import Image from 'react-bootstrap/Image'
import marquee from '../../images/Marquee.jpg'

import './marquee.css';
function Marquee(props) {
    const showDate = props.nextShow.datetime
    let dateString = new Date(showDate).toDateString();
    const { city, region, date, name, streetaddress, postal_code, location } = props.nextShow.venue;
    return (

        <figure className='marquee'>
            <div className='txtover'>
                <p style={{top: '-2px', fontSize:'12px'}}><strong>Next Showing</strong></p>
                <p itemProp="location" itemType="https://schema.org/Place" style={{ top: '15px', fontWeight: 'bold', margin: 0, fontSize: '20px' }}>{name}</p>
                <p style={{ top: '33px', fontWeight: 'bold', fontSize: '12px', margin: 0 }}><span>{dateString.toUpperCase()}<meta itemProp="startDate" content={showDate}/></span> 
                <span itemProp="addressLocality"> - {location.toUpperCase()}</span></p>
            </div>
            <Image itemProp='image' alt='Information for the next appearance by The Bantam String Benders displayed in theatre marquee style' src={marquee} className="marquee-img" />
        </figure>
    )
}

export default Marquee;