
import React, { useState, useEffect } from 'react';
import useScript from '../hooks/useScript';
import Container from 'react-bootstrap/Container';
import Show from './Show/show';
import { v4 as uuidv4 } from 'uuid';
import { ref, set, get, update, remove, child, getDatabase } from 'firebase/database';

function Shows(props) {
    
    useScript('https://widgetv3.bandsintown.com/main.min.js');
    const shows = props.shows;
    return (
  <Container style={{padding: 'unset'}}>
     <div style={{padding: '6px 0', backgroundColor: '#cfc11c', textAlign: 'center'}} >
       
       
<h2><strong><u>Upcoming shows</u></strong></h2>
  <a className="bit-widget-initializer"
    
	data-artist-name="id_15525763"
	
	data-background-color="#ffffff"
	data-separator-color="#DDDDDD"
	data-text-color="#424242"
	data-font="Helvetica"
	data-auto-style="true"
	
	data-button-label-capitalization="capitalize"
	data-header-capitalization="capitalize"
	data-location-capitalization="capitalize"
	data-venue-capitalization="capitalize"
	data-display-local-dates="false"
	data-local-dates-position="tab"
	data-display-past-dates="false"
	data-display-details="false"
	data-display-lineup="true"
	data-display-start-time="false"
	data-social-share-icon="true"
	data-display-limit="all"
	
	data-date-format="MMM. D, YYYY"
	data-date-orientation="horizontal"
	data-date-border-color="#4A4A4A"
	data-date-border-width="1px"
	data-date-capitalization="undefined"
	data-date-border-radius="10px"
	
	data-event-ticket-cta-size="medium"
	data-event-custom-ticket-text="undefined"
	data-event-ticket-text="Tickets"
	data-event-ticket-icon="false"
	data-event-ticket-cta-text-color="rgba(239,243,215,1)"
	data-event-ticket-cta-bg-color="rgba(181,173,82,1)"
	data-event-ticket-cta-border-color="rgba(110,106,26,1)"
	data-event-ticket-cta-border-width="1px"
	data-event-ticket-cta-border-radius="4px"
	
	data-sold-out-button-text-color="rgba(22,17,17,1)"
	data-sold-out-button-background-color="rgba(170,161,161,1)"
	data-sold-out-button-border-color="#4A4A4A"
	data-sold-out-button-clickable="true"
	
	data-event-rsvp-position="left"
	data-event-rsvp-cta-size="medium"
	data-event-rsvp-only-show-icon="true"
	data-event-rsvp-text="RSVP"
	data-event-rsvp-icon="true"
	data-event-rsvp-cta-text-color="#4A4A4A"
	data-event-rsvp-cta-bg-color="#FFFFFF"
	data-event-rsvp-cta-border-color="#4A4A4A"
	data-event-rsvp-cta-border-width="1px"
	data-event-rsvp-cta-border-radius="4px"
	
	data-follow-section-position="bottom"
	data-follow-section-alignment="center"
	data-follow-section-header-text="Get updates on new shows, new music, and more."
	data-follow-section-cta-size="medium"
	data-follow-section-cta-text="FOLLOW"
	data-follow-section-cta-icon="true"
	data-follow-section-cta-text-color="#FFFFFF"
	data-follow-section-cta-bg-color="#4A4A4A"
	data-follow-section-cta-border-color="#4A4A4A"
	data-follow-section-cta-border-width="0px"
	data-follow-section-cta-border-radius="4px"
	
	data-play-my-city-position="bottom"
	data-play-my-city-alignment="Center"
	data-play-my-city-header-text="Don’t see a show near you?"
	data-play-my-city-cta-size="medium"
	data-play-my-city-cta-text="REQUEST A SHOW"
	data-play-my-city-cta-icon="true"
	data-play-my-city-cta-text-color="#FFFFFF"
	data-play-my-city-cta-bg-color="#4A4A4A"
	data-play-my-city-cta-border-color="#4A4A4A"
	data-play-my-city-cta-border-width="0px"
	data-play-my-city-cta-border-radius="4px"
	
	data-optin-font=""
	data-optin-text-color=""
	data-optin-bg-color=""
	data-optin-cta-text-color=""
	data-optin-cta-bg-color=""
	data-optin-cta-border-width=""
	data-optin-cta-border-radius=""
	data-optin-cta-border-color=""
	
	data-language="en"
	data-layout-breakpoint="900"
	data-app-id=""
	data-affil-code=""
	data-bit-logo-position="bottomRight"
	data-bit-logo-color="#CCCCCC"
	
    ></a>
    </div>
    </Container>
    )
}



export default Shows;
