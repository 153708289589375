import React from 'react';
import { BsEnvelope, BsYoutube, BsFacebook, BsInstagram} from "react-icons/bs";
import { FaMusic, FaTiktok } from 'react-icons/fa';

import './socialLinks.css';
function Social (props){
    
const icon_style = {color: '#3e3a06'}
    return(
        <>
        <div className='social-links'>
        <ul className="social-ul" >
                    <li className="nav-item">
                        <a title='Facebook' className="nav-link" href="https://www.facebook.com/bantamstringbenders"><BsFacebook style={icon_style}/></a>
                    </li>
                    <li className="nav-item">
                        <a title='Instagram' className="nav-link" href="https://www.instagram.com/thebantamstringbenders/"><BsInstagram style={icon_style}/></a>
                    </li>
                    <li className="nav-item">
                        <a title='TikTok' className="nav-link" href="https://www.tiktok.com/@the.bantam.string/"><FaTiktok style={icon_style}/></a>
                    </li>
                    <li className="nav-item">
                        <a title= 'YouTube' className="nav-link" href="https://www.youtube.com/channel/UC_KHMIwZcTfqnhlNjHvVoIw"><BsYoutube style={icon_style}/></a>
                    </li>
                    <li className="nav-item">
                        <a title='Bandcamp' className="nav-link" href="https://thebantamstringbenders.bandcamp.com/"><FaMusic style={icon_style}/></a>
                    </li>
                    <li className="nav-item">
                        <a title='Contact' className="nav-link" onClick={() => props.handleShow()} /*href="mailto:contact@bantamstringbenders.com"*/><BsEnvelope style={icon_style}/></a>
                    </li>

                </ul>
          </div>
        </>
    )
}

export default Social;