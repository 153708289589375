import React, { useState, useRef, useEffect } from 'react';
import './subscribe.css'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { push, child, update } from "firebase/database";


const sendEmailURL = 'https://us-central1-bantam-string-benders-site.cloudfunctions.net/sendSubscriptionConfirmation';
function Subscribe(props) {
    let db = props.dbRef;
    let formRef = useRef();
    let footerDivRef = useRef();
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);

    const [showAlert, setShowAlert] = useState(false);

    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        if (id === "name") {
            setName(value);
        }
        if (id === "email") {
            setEmail(value);
        }

    }

    const validateInput = ({ name, email }) => {
        if (!name || !name.trim()) {
            return false;
        }

        if (!email || !email.trim()) {
            return false;
        }
        // other validations

        return true;
    };

    const sendEmail = () => {
        console.log('sendEmail executing...')
        const subject = 'Thank you for subscribing!'
        let message = `<h3><p>${name},</p><p>thank you for signing up to The Bantam String Benders mailing list!  You will now receive updates and other benefits exclusive to mailing list subscribers!</p></h3>`
        let url = `${sendEmailURL}?recipient=${email}&subject=${subject}&name=${name}&message=${message}`

        fetch(url).then(res => res.json()).then(data => console.log(data));
    }

    const flashError = (err) => {
        setShowError(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const validInput = validateInput({ name, email });

        if (!validInput) {
            setErrorMessage('Invalid Input');
            flashError();
            return null;
        }

        let obj = {
            name: name,
            email: email,
        }
        const newPostKey = push(child(db, 'posts')).key;
        const updates = {};
        updates['mailingListSubs/' + newPostKey] = obj
        update(db, updates).then(r => {
            formRef.current.reset();
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, "4000")
            sendEmail();
        })
    }


    return (
        <div className='main'>
            <Form ref={formRef} className="form">
                <div className='form-header'>
                    <p>Mailing List Sign-up</p>
                </div>
                <div style={{ textAlign: 'center' }} className="form-body">
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
                        <Form.Control required='required' type="name" id='name' placeholder="Enter first and last name" onChange={(e) => handleInputChange(e)} />
                        <Form.Control.Feedback type="invalid">
                            Please enter your name.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                        <Form.Control required='required' type="email" id='email' placeholder="Enter email" onChange={(e) => handleInputChange(e)} />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid email address.
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted form__input">
                            <p style={{ color: '#c8c827' }}>We'll never share your email with anyone else.</p>
                        </Form.Text>
                        {showAlert ?
                            <Alert style={{padding: '3px'}} variant='success'>
                                Success! Thank you for signing up!
                            </Alert>
                            : null
                        }
                        {showError ?
                        <Alert style={{padding: '3px'}} variant='danger' dismissible>
                            <p style={{marginBottom: 0, fontSize: "12px"}}>Something went wrong.</p>
                            <p style={{marginBottom: 0, fontSize: "12px"}}>{errorMessage}</p>
                        </Alert>
                        : null
                    }
                    </Form.Group>
                </div>
                <div ref={footerDivRef} className="footer">
                    <Button style={{ color: '#2b3100', borderColor: '#2b3100', backgroundColor: "#d0d030", marginTop: '8px' }} className='submit-btn' onClick={handleSubmit} type="submit" >Register</Button>
                </div>
            </Form>
        </div>
    )
}
export default Subscribe;